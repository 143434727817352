/** @jsx jsx */
import React from "react";
import { useHubSpotScript } from "../hooks/useHubSpotScript";
import { graphql } from "gatsby";
import { jsx, Container, Heading } from 'theme-ui';
import Layout from '../components/Layout';

export const pageQuery = graphql`
  query HubspotFormPageQuery($id: String) {
    contentfulHubspotForm(id: { eq: $id }) {
      id
      slug
      region
      portalId
      formId
      name
    }
  }
`;

export default function HubspotFormPageTemplate({ data }) {
  const { name, region, portalId, formId } = data.contentfulHubspotForm;
  const containerId = "form-container";

  useHubSpotScript(containerId, region, portalId, formId);

  return (
    <Layout>
      <Container>
        <Heading as="h1" sx={{ marginBottom: 4 }}>{name}</Heading>
        <div id={containerId}></div>
      </Container>
    </Layout>
  );
};
